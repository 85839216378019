import { createRef } from "react"

const state = {
  selectedContent: 0,
  sections: 9,
  pages: 12,
  zoom: 75,
  font: "/fonts/helvetiker_regular.typeface.json",
  brews: [
    {
      brewery: "Omnipollo",
      name: "Spirals",
      style: "Pale Ale - American",
      label_image: "https://untappd.akamaized.net/site/brewery_logos/brewery-Omnipollo_29486_68ed9.jpeg"
    },
    {
      brewery: "Omnipollo",
      name: "Braraba",
      style: "Sour - Fruited",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-3745125_85e68_sm.jpeg"
    },
    {
      brewery: "Omnipollo",
      name: "Phantastica",
      style: "IPA - Triple",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-3626480_673ea_sm.jpeg"
    },
    {
      brewery: "Crooked Stave Artisan Beer Project",
      name: "Sour Rosé",
      style: "American Wild Ale",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-1702801_4f7d8_sm.jpeg"
    },
    {
      brewery: "Omnipollo",
      name: "Flora",
      style: "Sour - Other",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-3215658_9bdb2_sm.jpeg"
    },
    {
      brewery: "Omnipollo",
      name: "I Wanna Be Your Dog",
      style: "Stout - Imperial / Double Pastry",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-2269438_946e4_sm.jpeg"
    },
    {
      brewery: "Omnipollo",
      name: "Perikles",
      style: "Rustic Pilsner",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-1042478_c9cb1_sm.jpeg"
    },
    {
      brewery: "Omnipollo",
      name: "Levon",
      style: "Pale Ale - Belgian",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-71307_528f5_sm.jpeg"
    },
    {
      brewery: "Omnipollo",
      name: "Zodiak",
      style: "IPA - American",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-852701_0f2df_sm.jpeg"
    },
    {
      brewery: "Omnipollo",
      name: "Gayatri",
      style: "IPA - Imperial / Double",
      label_image: "https://untappd.akamaized.net/site/beer_logos/beer-3714441_898ba_sm.jpeg"
    }
  ],
  stripes: [
    { offset: 0, color: "#FF0000", height: 20 },
    { offset: 3, color: "#FF0000", height: 20 },
    { offset: 6, color: "#FF0000", height: 20 }
  ],
  top: createRef()
}

export default state
