import ReactDOM from "react-dom"
import React, { Suspense, useState, useCallback, useEffect, useRef, useMemo } from "react"
import { createApolloFetch } from "apollo-fetch"
import { TextureLoader, LinearFilter } from "three"
import lerp from "lerp"
import Model from "./components/Model"
import Menu from "./components/Menu/Menu"
import Stripe from "./components/Stripe"
import Simple from "./components/Simple/Simple"
import Brew from "./components/Brew"
import Info from "./components/Info"
import { Block, useBlock } from "./blocks"
import state from "./store"
import "./styles.css"

function BackGround(props) {
  return (
    <mesh receiveShadow position={[0, 0, -7]}>
      <planeBufferGeometry attach="geometry" args={[100, 100, 32, 32]} />
      <shadowMaterial attach="material" color={props.shadowColor} opacity={0.15} />
    </mesh>
  )
}

function Content(props) {
  /*
  const images = useLoader(
    TextureLoader,
    state.brews.map(({ label_image }) => label_image)
  )
  useMemo(() => images.forEach((texture) => (texture.minFilter = LinearFilter)), [images])
  */
  const { contentMaxWidth: w, canvasWidth, canvasHeight, mobile } = useBlock()
  return (
    <>
      <Block factor={2} offset={0}>
        <Model />
      </Block>
      {props.selectedContent === 0 && props.beers.map((props, index) => <Brew key={index} index={index} {...props} />)}

      {props.selectedContent === 1 && (
        <Block factor={1.2} offset={0}>
          <Info textColor="#00FF00" planeColor="#9C17FF" text={"3 kinds of hot dogs and 5 kinds of side dishes produced by Caveman"} />
        </Block>
      )}

      {props.selectedContent === 2 && (
        <Block factor={1.2} offset={0}>
          <Info textColor="#0000FF" planeColor="#ff0000" text={"9-5 Nihonbashi\nKabutocho, Chuo-ku\nTokyo, Japan103-0026"} />
        </Block>
      )}

      {/*state.stripes.map(({ offset, color, height }, index) => (
        <Block key={index} factor={-1.5} offset={offset}>
          <Stripe args={[100, height, 32, 32]} shift={-4} color={color} rotation={[0, 0, Math.PI / 8]} position={[0, 0, -6]} />
        </Block>
      ))*/}
    </>
  )
}

function App() {
  const [shadowColor, setShadowColor] = useState(0x9c17ff)
  const [beers, setBeers] = useState([])

  function getBeerData() {
    const fetch = createApolloFetch({
      uri: "https://business.untappd.com/graphql"
    })

    fetch.use(({ request, options }, next) => {
      if (!options.headers) {
        options.headers = {}
      }
      options.headers["Authorization"] = "Basic am9uQG9tbmlwb2xsby5jb206WVViVHRYWW1RcnNQYzR5TmJLckg="

      next()
    })

    fetch({
      query: `{
        beerMenu:menu(id: 100014) {
          created_at
          description
          location {
            name
          }
          name
          section_collection {
            item_collection {
              brewery
              name
              style
              label_image
            }
          }
          updated_at
        }
      }`
    }).then((res) => {
      //console.log(res.data.beerMenu.section_collection[0].item_collection)
      setBeers(res.data.beerMenu.section_collection[0].item_collection)
      //setBeers([res.data.beerMenu.section_collection[0].item_collection[10]])
    })
  }

  useEffect(() => {
    console.log("Font done")
    getBeerData()
  }, [])

  function changeContent(input) {
    state.selectedContent = input
    if (input === 0) {
      setShadowColor(0x9c17ff)
    }
    if (input === 1) {
      setShadowColor(0xffec00)
    }
    if (input === 2) {
      setShadowColor(0x0ff00)
    }
  }

  return (
    <>
      <div className="tokyo">
        <Menu changeFunction={changeContent} />
        <div className="content">
          <Simple beers={beers} selectedContent={state.selectedContent} />
        </div>
        {/*
        <div className="content">
           
          <Canvas concurrent pixelRatio={1} gl={{ antialias: true }} orthographic camera={{ zoom: state.zoom - 50, position: [0, 0, 50], fov: 50 }} shadowMap>
            <ambientLight intensity={0.96} />
            <spotLight castShadow position={[-10, 10, 50]} angle={0.6} intensity={0.8} penumbra={0.1} shadowMapWidth={1024} shadowMapHeight={1024} />

            <Suspense fallback={<Dom center className="loading" children="Loading..." />}>
              <BackGround shadowColor={shadowColor} />
              <Content selectedContent={state.selectedContent} beers={beers} />
            </Suspense>
          </Canvas>
          <div className="scrollArea" ref={scrollArea} onScroll={onScroll}>
            {state.selectedContent === 0 && new Array(beers.length).fill().map((_, index) => <div key={index} id={"0" + index} style={{ height: `55vh` }} />)}
            {state.selectedContent === 1 && <div style={{ height: `150vh` }} />}
            {state.selectedContent === 2 && <div style={{ height: `150vh` }} />}
          </div>
        </div>
        */}
      </div>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))
