import * as THREE from "three"
import React, { forwardRef, useMemo } from "react"
import { useLoader, useUpdate } from "react-three-fiber"
import state from "../store"

const OmniText = forwardRef(({ children, vAlign = "center", hAlign = "center", size = 1, color = "#ff0000", ...props }, ref) => {
  function measureText(ctx, textToMeasure) {
    return ctx.measureText(textToMeasure).width
  }
  function getPowerOfTwo(value, pow) {
    var pow = pow || 1
    while (pow < value) {
      pow *= 2
    }
    return pow
  }
  function createMultilineText(ctx, textToWrite, maxWidth, text) {
    textToWrite = textToWrite.replace("\n", " ")
    var currentText = textToWrite
    var futureText
    var subWidth = 0
    var maxLineWidth = 0

    var wordArray = textToWrite.split(" ")
    var wordsInCurrent, wordArrayLength
    wordsInCurrent = wordArrayLength = wordArray.length

    // Reduce currentText until it is less than maxWidth or is a single word
    // futureText var keeps track of text not yet written to a text line
    while (measureText(ctx, currentText) > maxWidth && wordsInCurrent > 1) {
      wordsInCurrent--
      var linebreak = false

      currentText = futureText = ""
      for (var i = 0; i < wordArrayLength; i++) {
        if (i < wordsInCurrent) {
          currentText += wordArray[i]
          if (i + 1 < wordsInCurrent) {
            currentText += " "
          }
        } else {
          futureText += wordArray[i]
          if (i + 1 < wordArrayLength) {
            futureText += " "
          }
        }
      }
    }
    text.push(currentText) // Write this line of text to the array
    maxLineWidth = measureText(ctx, currentText)

    // If there is any text left to be written call the function again
    if (futureText) {
      subWidth = createMultilineText(ctx, futureText, maxWidth, text)
      if (subWidth > maxLineWidth) {
        maxLineWidth = subWidth
      }
    }

    // Return the maximum line width
    return maxLineWidth
  }

  var canvas = document.getElementById("textureCanvas")
  var ctx = canvas.getContext("2d")
  var text = []

  var canvasX, canvasY
  var textX, textY
  var textHeight = 12
  var maxWidth = 150

  // Omitted: Set up the canvas and get the context

  ctx.font = textHeight + "px monospace"
  maxWidth = createMultilineText(ctx, children, maxWidth, text)
  canvasX = getPowerOfTwo(maxWidth)
  canvasY = getPowerOfTwo(textHeight * (text.length + 1))

  // Omitted: Set canvas width / height
  // Omitted: Set text properties

  textX = canvasX / 2
  var canvasOffset = (canvasY - textHeight * (text.length + 1)) * 0.5

  for (var i = 0; i < text.length; i++) {
    textY = (i + 1) * textHeight + canvasOffset
    ctx.fillText(text[i], textX, textY)
  }

  console.log(children)
  console.log(maxWidth)
  console.log(text)

  const font = useLoader(THREE.FontLoader, state.font)
  const config = useMemo(() => ({ font, size: 10, height: 50 }), [font])
  const mesh = useUpdate(
    (self) => {
      console.log("Local font loaded")
      const size = new THREE.Vector3()
      self.geometry.computeBoundingBox()
      self.geometry.boundingBox.getSize(size)
      self.position.x = hAlign === "center" ? -size.x / 2 : hAlign === "right" ? 0 : -size.x
      self.position.y = vAlign === "center" ? -size.y / 2 : vAlign === "top" ? 0 : -size.y
    },
    [children]
  )
  return (
    <group ref={ref} {...props} scale={[0.1 * size, 0.1 * size, 0.1]}>
      <mesh ref={mesh}>
        <textGeometry attach="geometry" args={[children, config]} />
        <meshNormalMaterial attach="material" />
      </mesh>
    </group>
  )
})

export default OmniText
