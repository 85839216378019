import React from "react"
import styled from "styled-components"

const Info = styled.div`
  width: 100%;
  text-align: center;
  background-color: #9c17ff;
  margin-bottom: 60px;
  padding: 10%;

  color: #00ff00;

  -webkit-box-shadow: 10px 10px 10px 0px rgba(255, 236, 0, 0.6);
  -moz-box-shadow: 10px 10px 10px 0px rgba(255, 236, 0, 0.6);
  box-shadow: 10px 10px 10px 0px rgba(255, 236, 0, 0.6);

  div {
    font-size: 32px;
    line-height: 1em;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`

function About(props) {
  return (
    <Info>
      <div>
        9-5 Nihonbashi
        <br />
        Kabutocho, Chuo-ku
        <br />
        Tokyo, Japan103-0026
        <br />
        <br />
        From Nihonbashi St. <br />5 minutes walk
        <br />
        (Subway Ginza line: G11, Tozai Line: T10)
        <br />
        Use Exit: D2 <br />
        <br />
        From Tokyo St. <br />
        15 minutes walk
        <br />
        Use Exit: B8b Nihonbashi exit <br />
      </div>
    </Info>
  )
}

export default About
