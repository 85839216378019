import React, { useState, useRef, useEffect } from "react"
import { Canvas, Dom, useLoader, useFrame, useThree, extend } from "react-three-fiber"
import { Block, useBlock } from "../blocks"
import Plane from "./Plane"
import * as THREE from "three"
import lerp from "lerp"
import { Text } from "troika-three-text"
import state from "../store"

// Register Text as a react-three-fiber element
extend({ Text })

function Info({ text, textColor, planeColor }) {
  let { contentMaxWidth: w, canvasWidth, margin, mobile } = useBlock()
  const { viewportHeight, offsetFactor } = useBlock()
  const offset = mobile ? 0.1 : 0.3
  const factor = 1.75
  const aspect = 2.2
  const size = mobile ? 3 : 3
  const planeScale = [w * size, (w * size) / aspect, 1]

  if (w > 9) {
    w = 9
  }

  const infoRef = useRef()
  const textRef = useRef()

  const [opts, setOpts] = useState({
    //font: "https://fonts.gstatic.com/s/notosans/v7/o-0IIpQlx3QUlC5A4PNr5TRG.woff",
    font: "Roboto-Medium.ttf",
    fontSize: mobile ? 0.5 : 1.5,
    position: mobile ? [0, 1.5, -1] : [0, 4, -1],
    color: textColor,
    maxWidth: mobile ? 8 : 20,
    lineHeight: 1.25,
    letterSpacing: 0,
    textAlign: "center",
    materialType: "MeshPhongMaterial"
  })

  return (
    <Block factor={factor} offset={offset}>
      <group position={[0, 0, 0]} ref={infoRef}>
        <Plane color={planeColor} castShadow args={[1, 1, 32, 32]} position={[0, 0, -5]} shift={75} size={size} aspect={aspect} scale={planeScale} frustumCulled={true} />

        <group>
          <text
            ref={textRef}
            position={opts.position}
            fontSize={opts.fontSize * opts.fontScale}
            {...opts}
            text={text.toUpperCase()}
            font={opts.font}
            anchorX="center"
            anchorY="top">
            {opts.materialType === "MeshPhongMaterial" ? <meshPhongMaterial attach="material" color={opts.color} /> : null}
          </text>
        </group>
      </group>
    </Block>
  )
}

export default Info
