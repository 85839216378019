import React, { useState, useEffect } from "react"
import { ReactComponent as Logo } from "./tokyo_meny-namn.svg"
import styled from "styled-components"

const MenuItem = styled.div`
  width: 100%;
  text-align: center;
  font-family: "ヒラギノ角ゴ Pro W6", "Hiragino kaku gothic W6", "Arial", sans-serif !important;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 10%;
  padding-right: 10%;
  display: inline-block;

  color: #ffffff;

  font-size: 32px;
  line-height: 1em;

  cursor: pointer;

  a {
    color: #ffffff;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

function Menu(props) {
  const [contentChange, setContentChange] = useState(0)

  useEffect(() => {
    props.changeFunction(contentChange)
  }, [contentChange])

  return (
    <>
      <div className="menuBg"></div>
      <div className="menuItems">
        <MenuItem onClick={() => setContentChange(0)}>ON TAP</MenuItem>
        <MenuItem onClick={() => setContentChange(1)}>FOOD</MenuItem>
        <MenuItem onClick={() => setContentChange(2)}>ABOUT</MenuItem>
        <MenuItem>
          <a href="https://omnipollos-tokyo.myshopify.com/" target="_new">
            STORE
          </a>
        </MenuItem>
      </div>
      <div className="logo">
        <Logo />
      </div>
    </>
  )
}

export default Menu
