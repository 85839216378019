import React from "react"
import styled from "styled-components"

const Brew = styled.div`
  width: 100%;
  text-align: center;
  background-color: blue;
  margin-bottom: 60px;
  padding: 10%;

  color: #ff0000;

  -webkit-box-shadow: 10px 10px 10px 0px rgba(156, 23, 255, 1);
  -moz-box-shadow: 10px 10px 10px 0px rgba(156, 23, 255, 1);
  box-shadow: 10px 10px 10px 0px rgba(156, 23, 255, 1);

  h1 {
    margin: 0;
    padding: 0;
    line-height: 1em;
    font-size: 60px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
  h1.small {
    font-size: 32px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  div {
    font-size: 32px;
    line-height: 1em;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`

function Brews(props) {
  const maxLengthOfWordConstant = 12

  props.beers.forEach(function (beer) {
    let maxLengthOfNameWords = 0
    beer.useSmallLetters = false
    const nameWords = beer.name.split(" ")
    nameWords.forEach(function (word) {
      if (word.length > maxLengthOfNameWords) {
        maxLengthOfNameWords = word.length
      }
    })
    if (maxLengthOfNameWords > maxLengthOfWordConstant) {
      beer.useSmallLetters = true
    }
  })

  return props.beers.map((beer, index) => (
    <Brew key={index} index={index}>
      <div>{beer.brewery.toUpperCase()}</div>
      <h1 className={`${beer.useSmallLetters ? "small" : ""}`}>{beer.name.toUpperCase()}</h1>
      <div>{beer.style.toUpperCase()}</div>
    </Brew>
  ))
}

export default Brews
