import React, { useState, useRef, useEffect } from "react"
import { Canvas, Dom, useLoader, useFrame, useThree, extend } from "react-three-fiber"
import { Block, useBlock } from "../blocks"
import Plane from "./Plane"
import * as THREE from "three"
import lerp from "lerp"
import { Text } from "troika-three-text"
import state from "../store"
import OmniText from "./OmniText"
import { Scene } from "three"

// Register Text as a react-three-fiber element
extend({ Text })

function Brew({ index, name, brewery, style, label_image }) {
  if (name.length > 50) name = name.substring(0, 50)
  //if (brewery.length > 50) brewery = brewery.substring(0, 50) + "..."
  //if (style.length > 30) style = style.substring(0, 30) + "..."
  /*
  let name_array = []
  let sub_name = null
  if (name.length > 25) {
    name_array = name.split(" ")
    if (name_array.length > 6) name_array = name_array.slice(0, 6)

    if (name_array.length == 2) {
      name = name_array[0]
      sub_name = name_array[1]
    }

    if (name_array.length == 3) {
      name = name_array[0] + " " + name_array[1]
      sub_name = name_array[2]
    }

    if (name_array.length == 4) {
      name = name_array[0] + " " + name_array[1]
      sub_name = name_array[2] + " " + name_array[3]
    }

    if (name_array.length == 5) {
      name = name_array[0] + " " + name_array[1] + " " + name_array[2]
      sub_name = name_array[3] + " " + name_array[4] + " " + name_array[5]
    }

    if (name_array.length == 6) {
      name = name_array[0] + " " + name_array[1] + " " + name_array[2]
      sub_name = name_array[3] + " " + name_array[4]
    }
  }
  */

  let { contentMaxWidth: w, canvasHeight: h, canvasWidth, margin, mobile } = useBlock()
  const offset = mobile ? index * 0.25 : 0.2 + 0.35 * index
  const factor = 1.75
  const aspect = 2.5
  const size = mobile ? 3 : 3
  const left = !(index % 2)
  if (w > 9) {
    w = 9
  }
  const planeScale = [w * size, (w * size) / aspect, 1]

  const [rotation, setRotation] = useState([0, 0, 0, 0])
  const [scale, setScale] = useState([1, 1, 1])
  const [opts, setOpts] = useState({
    //font: "https://fonts.gstatic.com/s/notosans/v7/o-0IIpQlx3QUlC5A4PNr5TRG.woff",
    font: "Roboto-Medium.ttf",

    fontSizeBrewery: brewery.length < 25 ? 1.5 : 1,
    positionBrewery: mobile ? [0, 0.5, -1] : [0, 1.3, -1],

    fontSizeName: name.length < 22 ? 2.5 : 1.4,
    positionName: mobile ? [0, 0, -1] : [0, 0, -1],

    fontSizeStyle: style.length < 25 ? 1.5 : 1,
    positionStyle: mobile ? [0, -0.9, -1] : [0, -2.5, -1],

    fontScale: mobile ? 0.3 : 1,
    color: "#FF0000",
    maxWidth: 250,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: "center",
    materialType: "MeshPhongMaterial"
  })

  /*
  const texture = useLoader(THREE.TextureLoader, label_image)
  console.log(texture)
*/
  const brewRef = useRef()

  return (
    <Block factor={factor} offset={offset}>
      <group position={[0, 0, 0]} ref={brewRef}>
        <Plane color="#0000FF" castShadow args={[1, 1, 32, 32]} position={[0, 0, -5]} shift={75} size={size} aspect={aspect} scale={planeScale} frustumCulled={true} />

        <group>
          <text
            position={opts.positionBrewery}
            fontSize={opts.fontSizeBrewery * opts.fontScale}
            {...opts}
            text={brewery.toUpperCase()}
            font={opts.font}
            anchorX="center"
            anchorY="bottom-baseline">
            {opts.materialType === "MeshPhongMaterial" ? <meshPhongMaterial attach="material" color={opts.color} /> : null}
          </text>

          <text position={opts.positionName} fontSize={opts.fontSizeName * opts.fontScale} {...opts} text={name.toUpperCase()} font={opts.font} anchorX="center" anchorY="middle">
            {opts.materialType === "MeshPhongMaterial" ? <meshPhongMaterial attach="material" color={opts.color} /> : null}
          </text>

          <text
            position={opts.positionStyle}
            fontSize={opts.fontSizeStyle * opts.fontScale}
            {...opts}
            text={style.toUpperCase()}
            font={opts.font}
            anchorX="center"
            anchorY="top-baseline">
            {opts.materialType === "MeshPhongMaterial" ? <meshPhongMaterial attach="material" color={opts.color} /> : null}
          </text>
        </group>
      </group>
    </Block>
  )
}

export default Brew
