import React from "react"
import styled from "styled-components"
import Logo from "./Logo"
import Brews from "./Brews"
import Food from "./Food"
import About from "./About"

const SimpleHolder = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  max-width: 600px;
  width: 85%;
  text-align: center;
  font-family: "ヒラギノ角ゴ Pro W6", "Hiragino kaku gothic W6", "Arial", sans-serif !important;

  img {
    width: 100%;
  }
`

function Simple(props) {
  return (
    <SimpleHolder>
      <Logo />
      {props.selectedContent === 0 && <Brews beers={props.beers} />}
      {props.selectedContent === 1 && <Food />}
      {props.selectedContent === 2 && <About />}
    </SimpleHolder>
  )
}

export default Simple
