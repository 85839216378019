import React from "react"
import styled from "styled-components"
import logo from "./goldlogo-min.png"

const SimpleLogo = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 60px;

  img {
    width: 100%;
    max-width: 500px;
  }
`

function Logo(props) {
  return (
    <>
      <SimpleLogo>
        <img src={logo} />
      </SimpleLogo>
    </>
  )
}

export default Logo
