import React from "react"
import styled from "styled-components"

const Info = styled.div`
  width: 100%;
  text-align: center;
  background-color: #ff0000;
  margin-bottom: 60px;
  padding: 10%;

  color: #0000ff;

  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 255, 0, 0.6);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 255, 0, 0.6);
  box-shadow: 10px 10px 10px 0px rgba(0, 255, 0, 0.6);

  div {
    font-size: 32px;
    line-height: 1em;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`

function Food(props) {
  return (
    <Info>
      <div>3 kinds of hot dogs and 5 kinds of side dishes produced by Caveman</div>
    </Info>
  )
}

export default Food
